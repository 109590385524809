import React from "react"
import AppLandingPage from "../components/app-landing-page"
import { Link } from "gatsby"
import ScreenshotIphone from "../components/ScreenshotIphone"
import { screenshotItem } from "../components/app-landing-page.module.css"
import Seo from "../components/seo"

const IntervalTimerIphone = () => (
  <>
    <Seo
      title="Intervals Pro: Tabata & HIIT for iPad"
      keywords={[
        "interval timer",
        "tabata timer",
        "workout timer",
        "exercise timer",
        "hiit timer",
        "run timer",
        "gym timer",
        "interval",
      ]}
      description="A simple, powerful, and customizable interval timer"
    />
    <AppLandingPage
      title="Intervals Pro: Tabata & HIIT for iPad"
      screenshots={[
        <ScreenshotIphone
          className={screenshotItem}
          type="reset"
          key="reset-iphone"
        />,
        <ScreenshotIphone
          className={screenshotItem}
          type="move"
          key="move-iphone"
        />,
        <ScreenshotIphone
          className={screenshotItem}
          type="complete"
          key="complete-iphone"
        />,
      ]}
      subheadline={
        <span>
          Available on all your devices:{" "}
          <Link to="/on-iphone" key="iphone">
            iPhone
          </Link>
          ,{" "}
          <Link to="/on-ipad" key="ipad">
            iPad
          </Link>
          ,{" "}
          <Link to="/on-mac" key="mac">
            Mac
          </Link>
          , &{" "}
          <Link to="/on-apple-tv" key="tv">
            Apple TV
          </Link>
          .
        </span>
      }
      conversionLink="https://apps.apple.com/app/apple-store/id1516292869?pt=1963985&ct=m-1-blw-1&mt=8"
      breadcrumbs={
        <ol itemScope itemType="https://schema.org/BreadcrumbList">
          <li
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
          >
            <a itemProp="item" href="/">
              <span itemProp="name">Intervals Pro: Tabata & HIIT</span>
            </a>
            <meta itemProp="position" content="1" />
          </li>
          ›
          <li
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
          >
            <a
              itemScope
              itemType="https://schema.org/WebPage"
              itemProp="item"
              itemID="/on-mac"
              href="/on-mac"
            >
              <span itemProp="name">On iPad</span>
            </a>
            <meta itemProp="position" content="2" />
          </li>
        </ol>
      }
    />
  </>
)

export default IntervalTimerIphone
